.App .RootGrid {
  /* height: 100vh; Fallback for browsers that do not support Custom Properties */
  height: 100%;
  min-height: 100%;
  /* height: calc(var(--vh, 1vh) * 100); */
  /* max-height: -webkit-fill-available; */
}

@media (prefers-color-scheme: dark) {
  /* .leaflet-layer, */
  .leaflet-control-zoom-in,
  .leaflet-control-zoom-out,
  .leaflet-control-attribution,
  .toBeInverted {
    filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%);
  }

  .bg-green-700 {
    background-color: black;
  }
  .bg-green-600 {
    background-color: black;
  }
  .bg-white {
    background-color: white;
    opacity: 0.9;
  }

  .border-green-700 {
    border-color: black;
  }

  .border-green-600 {
    border-color: black;
  }
}
